import React from "react";
import { useState, useEffect, useRef } from "react"
import { isDarkMode } from "../../utils/constants"
import { Chart, registerables } from "chart.js"
import { isMobileBrowser } from "../../utils/user_agent";
import { formatLargeNumbers } from './utils'
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(...registerables, zoomPlugin);

const charting = (props) => {
  const {
    idx, messageThread, hasRendered, isLastMessage,  loadingResponse, showLoadingWaitingMessage, showLoadingWaitingMessageRef, questionOnDemand, questionOnDemandRef, setShowLoadingWaitingMessage,setHasRendered,
  } = props

  const isMobile = isMobileBrowser()

  useEffect(() => {
    if (!loadingResponse && (showLoadingWaitingMessageRef.current !== null)) {
      setShowLoadingWaitingMessage(0)
    }
    if (questionOnDemand) {
      setTimeout(() => {
        if (loadingResponse && isLastMessage && (showLoadingWaitingMessageRef.current == 0)) {
          setShowLoadingWaitingMessage("demand-1")
        }
      }, 2000)
    }
    else {

    setTimeout(() => {
      if (loadingResponse && isLastMessage && (showLoadingWaitingMessageRef.current !== null) && (questionOnDemandRef.current !== true)) {
        setShowLoadingWaitingMessage(1)
      }
    }, 10000)
    setTimeout(() => {
      if (loadingResponse && isLastMessage && (showLoadingWaitingMessageRef.current !== null) && (questionOnDemandRef.current !== true)) {
        setShowLoadingWaitingMessage(2)
      }
    }, 16000)
    setTimeout(() => {
      if (loadingResponse && isLastMessage && (showLoadingWaitingMessageRef.current !== null) && (questionOnDemandRef.current !== true)) {
        setShowLoadingWaitingMessage(3)
      }
    }, 22000)
    setTimeout(() => {
      if (loadingResponse && isLastMessage && (showLoadingWaitingMessageRef.current !== null) && (questionOnDemandRef.current !== true)) {
        setShowLoadingWaitingMessage(4)
      }
    }, 28000)

    }

  }, [loadingResponse && isLastMessage, showLoadingWaitingMessage, questionOnDemand])

  const [chartInstance, setChartInstance] = useState(null);
  const chartContainerRef = useRef(null);

  useEffect(() => {
    if (messageThread.tickerDescription && messageThread.values2) {
    
    const label1 = messageThread.label1
    const label2 = messageThread.label2
    const label3 = messageThread.label2//3
    const dates = messageThread.dates
    const values1 = messageThread.values1
    const values2 = messageThread.values2
    const values3 = messageThread.values2//3

    let showBarChart = false

    const barChartKeywords = [
      "Insider Transactions", "Volume",
    ]
    if (barChartKeywords.includes(label1)) {
      showBarChart = true
    }
    if (dates.length < 1 || values2.length < 1) { return }
    setHasRendered(true)

    if (chartContainerRef.current) {

      const element = document.getElementById(`myChart-${idx}`)
      
      if (!element) { return }
      
      let barColor = 'rgba(10,10,255, 0.25)';
      let epsColor = 'rgba(10,10,255, 0.75)';//'rgba(252,72,114, 0.7)';
      let revColor = 'rgba(45,143,230, 0.7)';
      let priceColor = 'rgba(95,95,95, 0.45)';
      if (isDarkMode) {
        barColor = 'rgba(100,150,255, 0.45)';
        epsColor = 'rgba(100,150,255, 0.95)'//'rgba(252,72,114, 0.7)';
        revColor = 'rgba(45,143,230, 0.7)';
        priceColor = 'rgba(195,195,195, 0.9)';
      }

      if (chartInstance) {
        chartInstance.destroy();
      }
      const ctx = chartContainerRef.current.getContext('2d');

      let datasets = [
        {
          label: label3,
          data: values3,
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
          borderColor: priceColor,
          textColor: priceColor,
          borderWidth: 1,
          yAxisID: 'stockPriceAxis',
          pointRadius: 0, // For the stock price, no need
        }
      ]
      if (values1 && values1.length > 0) {
        datasets = [{
          label: label1,//'Earnings Per Share',
          data: values1,//dates.map((date, index) => ({ x: date, y: values1[index] })),//epss,
          type: showBarChart ? 'bar': null,
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
          borderColor: showBarChart ? barColor: epsColor,//epsColor,
          textColor: epsColor,
          color: epsColor,
          labelColor: epsColor,
          borderWidth: showBarChart ? 1 : 2,
          barThickness: 2,
          yAxisID: 'epsAxis',
          backgroundColor: showBarChart ? barColor: epsColor,
          pointRadius: 0,//2, // Size of the point
          pointBackgroundColor: showBarChart ? barColor: epsColor,//epsColor, // Background color of the point
          pointBorderColor: showBarChart ? barColor: epsColor,//epsColor, // Border color of the point
          pointStyle: 'circle' // Shape of the point
        }, datasets[0]]
      }

      const newChartInstance = new Chart(ctx, {
        type: 'line', // Change the type to your desired chart type
        data: {
          labels: dates,//allDates,
          datasets: datasets
        },
        options: {
          responsive: true,
          maintainAspectRatio: false, 
          scales: {
            
            x: {
              ticks: {
                color: priceColor,
                maxTicksLimit: 20, 
                font: {
                  size: 11
                },
                callback: function(value, index, values) {
                  const dateStr = dates[value]
                  const date = new Date(dateStr);
                  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                  return months[date.getMonth()] + " '" + date.getFullYear().toString().slice(2,4)
                }
              },
              grid: {
                display: false, // This will hide the grid lines for the X axis
              },
            },
            epsAxis: {
              type: 'linear',
              display: (values1 && values1.length > 0),
              position: 'left',
              color: epsColor,
              textColor: epsColor,
              beginAtZero: false,

              ticks: {
                color: epsColor,
                font: {
                  size: 11
                },
                callback: function(value, index, values) {
                  return formatLargeNumbers(Math.round(100*value)/100);
                }
              },
              grid: {
                display: false, // This will hide the grid lines for the X axis
              },
              afterDataLimits: axis => {
                if (showBarChart) {
                  axis.max = axis.max * 2;
                }
              },
            },
            stockPriceAxis: {
              type: 'linear',
              display: true,
              position: 'right',
              color: priceColor,
              ticks: {
                color: priceColor,
                font: {
                  size: 11
                },
                callback: function(value, index, values) {
                  return '$' + value.toLocaleString();
                }
              },
              beginAtZero: false,
              grid: {
                display: false, // This will hide the grid lines for the X axis
              },
            },
          },
          plugins: {
              title: {
              display: true,
              text: `${messageThread.tickerDescription[0]}`,
              font: {
                size: 14
              },
              position: 'top',
            },
            zoom: {
              pan: {
                enabled: !isMobile,
                mode: 'x',
              },
              zoom: {
                wheel: {
                  enabled: !isMobile,
                },
                pinch: {
                  enabled: !isMobile,
                },
                mode: 'x',
              },
            },
            legend: {
              labels: {
                // CAN MAKE CIRCLE: (Comment out to revert to square)
                usePointStyle: true, // This will use point style (circle) instead of box
                pointStyle: 'circle',
                boxWidth: 30,
                padding: 10, // Optional: if you want some padding
                // You can also set the font color and size here, if needed
                font: {
                  size: 12, // Example size
                  weight: 'bold', // Optional: if you want to set a font weight
                },
                // More styling can be done here as required
              },
              // Other legend options...
            }
          }
        }
      });

      setChartInstance(newChartInstance);

      }
    }
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };

  }, [isLastMessage, hasRendered, messageThread.tickerPrices]);

  return chartContainerRef
}

export default charting
