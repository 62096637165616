import { Box, Link } from '@mui/material';
import '../../stylesheets/welcome_prechat.css'
import Back from './Reusable/Back';
import { useEffect, useState } from 'react';
import SignIn from './SignIn';
import SignUp from './SignUp';
import PrimaryButton from './Mui/Button';
import { useNavigate } from 'react-router-dom';
import { isDarkMode, themeColors, appleRedirectUrl } from "../utils/constants";
import GoogleIcon from '../../javascript/images/GoogleIcon.png'
import AppleIconDark from '../../javascript/images/AppleIcon.png'
import AppleIcon from '../../javascript/images/AppleIconDark.png'
import MailIcon from '../../javascript/images/MailIcon.png'
import { GoogleLogin } from '@react-oauth/google';
import AppleLogin from 'react-apple-login';
import PrintOutText from "./Chat/PrintOutText";

const NewAccountPage = (props) => {
  const navigate = useNavigate()
  const {
    appProps,
    signInOrUp,
    setSignInOrUp,
    signInType, setSignInType,
  } = props

  const {
    registerNavMessage, 
    setRegisterNavMessage, userData, setUserData,
    setShowPaywallModal,
    showPaywallAfter,
  } = appProps

  const [goToChat, setGoToChat] = useState(false)
  const [errors, setErrors] = useState([])//"Congratulations, you played yourself"])
  const [ messageText, setMessageText ] = useState("")

  useEffect(() => {
    if (registerNavMessage) {
      setTimeout(() => {
        const characters = registerNavMessage.split('')
        let curStr = ''
        characters.forEach((char, index) => {
          setTimeout(() => {
            curStr = curStr.concat(char)
            setMessageText(curStr);
          }, index * 50);
        })
      }, 100)
    }
  }, [registerNavMessage])

  useEffect(() => {
    if (goToChat) {
      // Show the paywall again!
      // if (showPaywallAfter) {
      //   setShowPaywallModal(true)
      // }
      navigate('/chat')
    }
  }, [goToChat])

  useEffect(() => {
    // If they are logged in, redirect back to chat from register
    if (userData.user_id) {
      navigate('/chat')
    }
  }, [userData])

  return (
    <Box sx={{height: '100vh',}}>
    {/* { signInOrUp == "sign-in" && */}
      <Back value={false}/>
    {/* } */}
    <Box sx={{
      height: '100%',
      overflowY: 'auto',
      backgroundColor: themeColors.backgroundColor,
      }}>
      <Box className="welcome-container" sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}>
        <Box sx={{color: 'rgb(255,0,0,.6)'}}>
          <ul>{errors.map((error) => {
            return <li key={error}>{error}</li>
            })}
          </ul>
        </Box>

        { registerNavMessage &&
        <Box sx={{height: '110px', margin: '0px'}}>
        <PrintOutText showDownArrowIcon={false} message={messageText} lineSpacing={[303, 250, 114]}/>
        </Box>
        }

        { signInType == "sign-in-email" &&
        <>
          <SignInApple userData={userData} setUserData={setUserData} setErrors={setErrors}/>
          <SignInGoogle userData={userData} setUserData={setUserData} setErrors={setErrors}/>
          <Box className="base-text register-message" sx={{display: 'flex', color: 'gray', marginBottom: '10px', marginTop: '20px',}}>
            <Box style={{width: '100px', margin: 'auto 0px', borderBottom: '1px solid gray'}}/> <Box sx={{margin: 'auto 10px'}}>or </Box> <Box style={{width: '100px', margin: 'auto 0px', borderBottom: '1px solid gray'}}/>
          </Box>
          <SignIn
            appProps={appProps}
            setGoToChat={setGoToChat}
          />
        </>
        }
        { signInType == "sign-up-email" &&
        <>
          <SignInApple userData={userData} setUserData={setUserData} setErrors={setErrors}/>
          <SignInGoogle userData={userData} setUserData={setUserData} setErrors={setErrors}/>
          <Box className="base-text register-message" sx={{display: 'flex', color: 'gray', marginBottom: '10px', marginTop: '20px',}}>
            <Box style={{width: '100px', margin: 'auto 0px', borderBottom: '1px solid gray'}}/> <Box sx={{margin: 'auto 10px'}}>or </Box> <Box style={{width: '100px', margin: 'auto 0px', borderBottom: '1px solid gray'}}/>
          </Box>
          <SignUp
            appProps={appProps}
            setGoToChat={setGoToChat}
          />

        </>
        }

        { signInOrUp == "sign-in"  &&
          <Box sx={{
            width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
            color: themeColors.color, marginTop: '30px',
            }}>
            <Box sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
              }}>
              {/* <Box sx={{
                  marginTop: '15px',
                  fontSize: '12px'
                }}
                className="base-text sign-text"
              >
                <Link 
                  sx={{'&:hover': {cursor: 'pointer'}, color: themeColors.linkColor, fontSize: '16px'}} 
                  onClick={() => {
                    setSignInOrUp('sign-up')
                    setSignInType("sign-up-email")
                    setRegisterNavMessage()
                  }}
                >Create an account
                </Link>

              </Box> */}
              <Box className="base-text" sx={{fontSize: '14px', marginTop: '10px', color: themeColors.color}}>
                  Don't have an account? <Link 
                    sx={{'&:hover': {cursor: 'pointer'}, color: themeColors.linkColor, fontSize: '16px'}} 
                    onClick={() => {
                      setSignInOrUp('sign-up')
                    setSignInType("sign-up-email")
                    setRegisterNavMessage()
                    }}
                  >Create Account
                  </Link>
                </Box>
              <Box sx={{
                  marginTop: '25px',
                  // fontSize: '12px',
                  color: themeColors.color,
                  fontSize: '14px',
                }}
                className="base-text"
              >
                Forgot your password? <Link 
                  sx={{'&:hover': {cursor: 'pointer'}, color: themeColors.linkColor, fontSize: '16px'}} 
                  onClick={() => {
                    // setRegisterNavMessage('Todo: Need to send email for password reset!')
                    navigate('/forgot-password')
                  }}
                >Reset
                </Link>
              </Box>
              
            </Box>
          </Box>
        }
        <Box sx={{height: '150px'}}/>
      </Box>
    </Box>
    </Box>
  )
}

export const SignInGoogle = (props) => {
  const {
    setErrors, userData, setUserData, onChatPage, setShowSignUpModal, overrideDarkMode
  } = props
  const navigate = useNavigate()
  const [ googleUser, setGoogleUser ] = useState(null);
  
  useEffect(() => {
    if (googleUser) {
      let user = {
        verified_email: true, // defaulting true for google
        subscribed_marketing: true,
      }
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id_token:  googleUser.credential,
          user: user,
        })
      };
      fetch('/google_create_account', requestOptions)
        .then(response => {
          return (response.json())
        })
        .then(data => {
          if (data.error_message) {
            setErrors(data.error_message)
          } else {
            setUserData({...userData, ...data, requireSignIn: false})
            if (setShowSignUpModal) {
              setShowSignUpModal(false)
            }
            // Now that we have callback; fire off facebook signup tracking:
            if (window.location.hostname !== "localhost") {
              fbq('track', 'SignUp');
            }
            navigate('/chat')
          }
        })
        .catch(error => {
          console.log('api errors:', error)
          setErrors(error[0])
        })
    }
    
  }, [googleUser]);

  // https://livefiredev.com/in-depth-guide-sign-in-with-google-in-a-react-js-application/
  return (
    // the "colorScheme white" what needed to make it exactly white!
    <Box sx={{marginTop: '12px', colorScheme: 'light'}}>
    <GoogleLogin
      onSuccess={credentialResponse => {
        setGoogleUser(credentialResponse)
      }}
      onError={() => {
        console.log('Login Failed');
      }}
      size="large"
      width="240"
      theme={(overrideDarkMode || isDarkMode) ? "outline" : "filled_black"}
      text="continue_with"
      shape="circle"
    />
    </Box>
  )
}

export const SignInApple = (props) => {
  const {
    setErrors, userData, setUserData, onChatPage, setShowSignUpModal, overrideDarkMode
  } = props
  const navigate = useNavigate()

  const appleResp = (response) => {
    if (!response.error) {
      // Note that response returns "code" as well which is needed to verify for Apple services etc on behalf of user.
      let user = {
        verified_email: true, // defaulting true for apple
        subscribed_marketing: true,
      }
      if (response.user) {
        user = {
          ...user,
          email: response.user.email,
          first_name: response.user.name.firstName,
          last_name: response.user.name.lastName,
        }
      }
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user: user,
          id_token: response.authorization.id_token,
        })
      };
      
      fetch('/apple_create_account', requestOptions)
      .then(response => {
        return (response.json())
      })
      .then(data => {
        if (data.error_message) {
          setErrors(data.error_message)
        } else {
          setUserData({...userData, ...data, requireSignIn: false})
          if (setShowSignUpModal) {
            setShowSignUpModal(false)
          }
          // Now that we have callback; fire off facebook signup tracking:
          if (window.location.hostname !== "localhost") {
            fbq('track', 'SignUp');
          }
          navigate('/chat')
        }
      })
      .catch(error => {
        console.log('api errors:', error)
        setErrors(error[0])
      })
    }
  }
  return (
    <AppleLogin
    clientId="com.inciteai.webapp.signin"
    redirectURI={appleRedirectUrl}
    usePopup={true}
    callback={appleResp}
    scope="email name"
    responseMode="query"
    render={renderProps => (
      <SignInUpLayout 
        text={"Continue with Apple"}
        icon={(overrideDarkMode || isDarkMode) ? AppleIconDark : AppleIcon}
        signType={"sign-in-apple"}
        onClickAction={renderProps.onClick}
        textStyles={{marginRight: '8px', color: (overrideDarkMode || isDarkMode) ? 'rgba(46,49,52,1.0)' : 'rgba(227,229,232,1.0)', fontSize: '13px'}}
        overrideDarkMode={overrideDarkMode}
      />
    )}
  />
  )
}

export const SignInUpEmail = (props) => {
  const { overrideDarkMode } = props
  const navigate = useNavigate()
  const onClick = () => {
    navigate('/register')
  }
  return (
    <SignInUpLayout 
      text={"Continue with Email"}
      icon={MailIcon}
      signType={"sign-in-email"}
      onClickAction={onClick}
      textStyles={{marginRight: '11px', color: (overrideDarkMode || isDarkMode) ? 'rgba(46,49,52,1.0)' : 'rgba(227,229,232,1.0)', fontSize: '13px'}}
      overrideDarkMode={overrideDarkMode}
    />
  )
}

const SignInUpLayout = (props) => {
  const { text, icon, sx, textStyles, onClickAction, overrideDarkMode } = props

  return (
    <Box sx={{width: '240px',maxWidth: '86%', height: '40px', marginTop: '14px', display: 'flex', alignItems: 'center', justifyContent: 'center', ...sx}}>
      <PrimaryButton overrideDarkMode={overrideDarkMode} onClick={() => onClickAction()}>
        <Box sx={{width: '100%', display: 'flex', alignItems: 'center',
        justifyContent: 'space-between',
        }}>
          <Box className="sign-in-icons">
            <img src={icon} style={{maxHeight: '100%', maxWidth: '100%'}}/> 
          </Box>
          <Box className="base-text sign-text" sx={{
            color: '#fff', marginRight: '12px',
          }}
          >
            <Box sx={{display: 'flex'}}>
              <Box sx={{...textStyles, fontWeight: '500'}}>{text}</Box>
            </Box>
          </Box>
        </Box>
      </PrimaryButton>
    </Box>
  )
}

export default NewAccountPage
