import { Box, Link } from '@mui/material';
import { Navbar } from "../components/Navbar";
import { useState } from 'react';
import Back from '../components/Reusable/Back';
import { themeColors } from '../utils/constants';
import { useNavigate } from 'react-router-dom';

const ConfirmEmail = (props) => {
  const {
    appProps
  } = props
  const {
    userData, setUserData, setShowSignUpModal,
    setShowPaywallModal,
    showPaywallAfter,
  } = appProps

  const navigate = useNavigate()
  const [ errorMessage, setErrorMessage ] = useState(null)

  const handleSubmit = (event) => {
    event.preventDefault()
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({query:
        `{
          checkEmailValidation {
            isEmailConfirmed
          }
        }` 
      })
    };
    fetch('/graphql', requestOptions)
    .then(response => {
      return (response.json())
    })
    .then(response => {
      const data = {
        is_email_confirmed: response.data.checkEmailValidation.isEmailConfirmed
      }
      setUserData({...userData, ...data})
      if (data.is_email_confirmed) {
        setShowSignUpModal(false)
        // Show the paywall again!
        // if (showPaywallAfter) {
        //   setShowPaywallModal(true)
        // }
        navigate('/chat')
      } else {
        setErrorMessage("It looks like your email is still not confirmed. If you believe this may be in error, please reach out to help@inciteai.com")
      }
    })
    .catch(error => {
      console.log('api errors:', error)
    })
  }

  return (
    <Box sx={{width: '100%', height: '100%'}}><Box>
    <Back value={false}/>
    <Box sx={{height:'100vh', overflowY: 'auto', backgroundColor: themeColors.backgroundColor, paddingRight: '24px', paddingLeft: '24px',}}>
      <Box className="welcome-container" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginTop: '50px'}}>
      <Box className="base-text" sx={{color: themeColors.color, textAlign: 'center', lineHeight: '180%'}}>
        <Box sx={{margin: '20px auto', color: 'rgb(255,0,0,.6)'}}>
          {errorMessage}
        </Box>
        Please check your inbox and verify your email.<br/><br/>
        Once Verified:
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <Box sx={{marginTop: '20px', fontSize: '14px'}} className="base-text sign-text">
          <Link 
            sx={{'&:hover': {cursor: 'pointer'}, color: themeColors.linkColor, fontSize: '18px'}} 
            onClick={handleSubmit}
          >Return to Chat
          </Link>
        </Box>
      </Box>
      <Box className="base-text" sx={{marginTop: '80px', color: themeColors.color, textAlign: 'center', lineHeight: '180%', fontSize: '12px'}}>
        Still can't find the email?<br/>Try checking your spam or email <a href="mailto:chat@inciteai.com" style={{ color: themeColors.linkColor}}>chat@inciteai.com</a> for help.
      </Box>
    </Box>
    </Box>
    </Box>
    <Box sx={{position: 'fixed', left: '0px', top: '0px', width: '100%'}}>
      <Navbar appProps={appProps} headerDescription={"Confirm Email"}/>
    </Box>
    </Box>
  )
}
export default ConfirmEmail
