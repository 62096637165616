import { Box, Button, Link } from '@mui/material';
import PaywallBackWhite from '../../javascript/images/PaywallBackWhite.svg'
import PaywallImageIpad from '../../javascript/images/PaywallImageIpad3.png'
import MarketProText from '../../javascript/images/MarketProText.png'
import MoneyBack from '../../javascript/images/MoneyBack.svg'
import PaywallMobile from '../../javascript/images/PaywallMobile.png'
import MarketProLine from '../../javascript/images/MarketProLine.png'
import PaywallFeatures from '../../javascript/images/PaywallFeatures.png'
import PaywallSelectCheck from '../../javascript/images/PaywallSelectCheck.png'
import IncitePlusLogo from '../../../public/IncitePlusLogo.png'
import InciteMarketPro from '../../../public/InciteMarketPro.png'
import '../../stylesheets/plan-selection.css'
import { useState, useEffect } from 'react';
import PaywallButton from '../components/Mui/PaywallButton';
import { useNavigate, useLocation } from 'react-router-dom';

const PlanSelection = () => {
  const navigate = useNavigate();
  const [planSelected, setPlanSelected] = useState('monthly');

  const [offerValue, setOfferValue] = useState(null)
  const [offerPresent, setOfferPresent] = useState(false)

  const location = useLocation()
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const offer = params.get('offer');
    setOfferValue(offer)
    if (offer === 'monthly-market-pro') {
      setOfferPresent(true)
    }
  }, [location.search])

  let continueUrl = `/create-checkout-session?plan=${planSelected}`
  if (offerValue != null) {// && planSelected == 'monthly') {
    // ^ We still want to pass value to annual plan selected as well. (So if nav back, will retain it)
    continueUrl += `&offer=${offerValue}`
  }

  return (
    <Box className="background" >
      <Box className="main-container" sx={{backgroundColor: 'black'}}>
        <Box className="back-button" onClick={() => navigate('/chat')}>
          <img src={PaywallBackWhite} style={{height: '16px', width: '16px'}} alt="Back" /> 
        </Box>
        <Box className="base-text">
          <img src={IncitePlusLogo} className="logo-img" alt="IncitePlus Logo" />
          {/* <Box className="header" sx={{fontWeight: '600', color: '#fff'}}> */}
          <Box sx={{marginTop: '12px'}}>
          {/* Market Pro */}
          <img src={MarketProText} style={{width: '180px'}} alt="Market Pro Text" />
          </Box>
          
          {/* <img src={InciteMarketPro} style={{maxHeight: '100%', maxWidth: '220px'}}/>  */}
        </Box>
        <Box className="base-text description" sx={{color: '#fff'}}>
          Incredibly Powerful. Financial Market AI.
        </Box>

        {/* <img className="pro-line" src={MarketProLine}/>  */}

        {/* <Box className="base-text description" sx={{marginTop: '0px', color: '#D45555'}}>
          You've reached your daily limit.
        </Box> */}

        {/* <Box className="images"> */}
        <Box className="images" sx={{marginTop: '20px', marginBottom: '8px'}}>
          <img src={PaywallImageIpad} style={{maxWidth: '100%',}} alt="Features" />
        </Box>

        {/* <Box className="base-text description" sx={{color: '#fff', marginTop: '0px',}}>
        A world of world-class<br/>intelligence.
        </Box> */}

        {/* <Box sx={{margin: '0px auto 0px auto'}}>
          <img src={MoneyBack} className="money-back"/>
        </Box> */}

      </Box>

      <Box className="base-text selection-area">
        <SelectionContainer offerPresent={offerPresent} plan={"monthly"} planSelected={planSelected} setPlanSelected={setPlanSelected}/>
      </Box>
      <Box className="base-text selection-area">
        {/* <SelectionContainer offerPresent={offerPresent} plan={"daily"} planSelected={planSelected} setPlanSelected={setPlanSelected}/> */}
        <SelectionContainer offerPresent={offerPresent} plan={"yearly"} planSelected={planSelected} setPlanSelected={setPlanSelected}/>
      </Box>

      <form action={continueUrl} method="POST">
        <Box className="button-container">
          <PaywallButton type="submit">
            <Box className="button-text" sx={{fontSize: '16px'}}>Continue</Box>
          </PaywallButton>
        </Box>
      </form>

      <Box className="footer">
        By subscribing you agree to our <Link className="link" href="https://inciteai.com/terms-and-conditions" target="_blank">Terms of Service.</Link> Subscriptions auto-renew until cancelled, as described in the Terms. Cancel anytime. A verified email is required to subscribe. If you’ve subscribed on another platform, manage your subscription through that platform. 
      </Box>
    </Box>
  );
}

const SelectionContainer = ({ offerPresent, plan, planSelected, setPlanSelected }) => {
  const isSelected = planSelected === plan;
  const monthlyOptions = {
    duration: 'Monthly',
    price: offerPresent ? '$4.99/mo.' : '$9.99/mo.',
    yearEquivalent: '$119.88'
  };
  const annualOptions = {
    duration: 'Annual',
    // price: '$99.99/yr.',
    price: '$8.33/mo.',
    yearEquivalent: '$119.88'
  };

  const dailyOptions = {
    duration: 'Day Pass',
    price: '$3.99',
  };

  let options
  let selectColor
  if (plan === "monthly") {
    options = monthlyOptions
    selectColor = '#3C79D5'
  } else if (plan == "yearly") {
    options = annualOptions
    selectColor = '#CE44FF'
  } else {
    options = dailyOptions
    selectColor = '#79A58B'
  }

  return (
    // <Box className={`selection-container ${isSelected ? 'selected' : ''}`} sx={{height: plan === "monthly" ? '108px':'132px', width: plan === "monthly" ? '100%':'50%'}}onClick={() => setPlanSelected(plan)}>
    <Box className={`selection-container ${isSelected ? 'selected' : ''}`} sx={{height: '108px', width: '100%'}}onClick={() => setPlanSelected(plan)}>
      {isSelected && (
        <Box className="check-mark">
          <img src={PaywallSelectCheck} style={{maxWidth: '16px'}} alt="Check" />
        </Box>
      )}
      <Box className="base-text option-duration">{options.duration} Pro <span style={{fontSize: '11px', opacity: 0.50}}>{(offerPresent && options.duration == 'Monthly') ? '(With 50% Discount, first 3 months)':''}</span></Box>
      <Box className="base-text option-price" style={{ color: selectColor }}>{options.price}</Box>
      <Box className="base-text option-detail">
        {plan === "monthly" &&
          "Billed monthly, Unlimited messages." 
        }
        {plan === "yearly" &&
          <>
          { offerPresent ?
            <>
              Billed Annually.
            </>
          :
            <>
              <span style={{color: selectColor}}>Save $19.89/yr.</span> with an Annual Subscription. Billed Annually.
              {/* Billed Annually. Unlimited messages. */}
            </>
          }
          </>
        }
        {plan === "daily" &&
          <>
            <span style={{color: selectColor}}>24 hour access.<br/>No Automatic Renewal.</span><br/>Starts at time of purchase.
          </>
        }
      </Box>
    </Box>
  );
}

export default PlanSelection;
