import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { useInsertionEffect, useState } from 'react'
import { chatSartingQuestions, returningChatStartingResponse, chatStartingResponse, startPlaceholderText } from '../utils/constants';
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { getUserSession } from '../api/user';
import { v4 as uuidv4 } from 'uuid';
import { clearChatMessageHistory } from '../api/message'
import ChatPage from '../pages/ChatPage';
import TermsOfUsePage from '../pages/TermsOfUsePage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import FAQsPage from '../pages/FAQsPage';
import ErrorPage from '../pages/ErrorPage';
import RegisterPage from '../pages/RegisterPage';
import AccountPage from '../pages/AccountPage';
import PriorityPage from '../pages/PriorityPage';
import FeedbackPage from '../pages/FeedbackPage';
import ContactPage from '../pages/ContactPage';
import ConfirmEmail from '../pages/ConfirmEmail';
import EmailConfirmed from '../pages/EmailConfirmed';
import ConfirmationFailed from '../pages/ConfirmationFailed';
import ForgotPassword from '../pages/ForgotPassword';
import PasswordReset from '../pages/PasswordReset';
import Unsubscribed from '../pages/Unsubscribed';
import PlanSelectionV2 from '../pages/PlanSelectionV2'
import { updateWatchlistData, refreshWatchlist } from '../api/user';
import { defaultPaywallModalMessage } from '../utils/constants';

const defaultState = {
  userData: {},
  currentMessages: [],
  messageThreads: [returningChatStartingResponse],
  hasShownHistory: false,
  requireSignIn: false,
}

const defaultResponseFlowState = {
  review: false,
  line: true,
  text: true,
  question1: [true, chatSartingQuestions[0].message, chatSartingQuestions[0].tickers, false, null],
  question2: [true, chatSartingQuestions[1].message, chatSartingQuestions[1].tickers, false, null], //not starting with advanced
  regen: true
}
 
function App() {
  const [userData, setUserData] = useState(defaultState.userData)
  const [currentMessages, setCurrentMessages] = useState(defaultState.currentMessages)
  const [inputPlaceholderText, setInputPlaceholderText] = useState('')//startPlaceholderText)
  const [messageThreads, setMessageThreads ] = useState(defaultState.messageThreads)//.concat(chatSartingQuestions))
  const [termsOfUseOpen, setTermsOfUseOpen] = useState(false)
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false)
  const [accountModalOpen, setAccountModalOpen] = useState(false)
  const [testVar, setTestVar] = useState(null)
  const [allowSetShit, setAllowSetShit] = useState(false)
  const [disclosureOpen, setDisclosureOpen] = useState(false)
  const [loadingUserData, setLoadingUserData] = useState(true)
  const [loadingUserMessages, setLoadingUserMessages] = useState(false)
  const [hasShownHistory, setHasShownHistory] = useState(defaultState.hasShownHistory)
  const [responseFlow, setResponseFlow] = useState(defaultResponseFlowState)
  const [questionButton, setQuestionButton] = useState([])
  const [registerNavMessage, setRegisterNavMessage] = useState(null)
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false)
  const [showChatShare, setShowChatShare] = useState(false)
  const [isNewUUID, setIsNewUUID] = useState(null)
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const [watchlistData, setWatchlistData] = useState({});
  const [lastRequestedUpdateWatchlist, setLastRequestedUpdateWatchlist] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [hasErroredWatchlist, setHasErroredWatchlist] = useState(false)
  const [paywallMessage, setPaywallMessage] = useState(defaultPaywallModalMessage)
  const [showWatchlistBlueDot, setShowWatchlistBlueDot]= useState(false)
  const [showWatchlistFlicker, setShowWatchlistFlicker]= useState(false)
  const [showPaywallAfter, setShowPaywallAfter] = useState(false)

  const requestClearChatMessageHistory = () => {
    let responseClear = clearChatMessageHistory(userData, appProps)
    responseClear.then(response => {
      setCurrentMessages([])
      setMessageThreads([chatStartingResponse].concat(chatSartingQuestions))
      setResponseFlow(responseFlow => ({
        ...responseFlow,
        question1: [true, chatSartingQuestions[0].message],
        question2: [true, chatSartingQuestions[1].message],
      }))
    })
  }

  const clearStateData = () => {
    setCurrentMessages(defaultState.currentMessages)
    // setMessageThreads(defaultState.messageThreads.concat(chatSartingQuestions))
    setMessageThreads([chatStartingResponse].concat(chatSartingQuestions))
    setResponseFlow(responseFlow => ({
      ...responseFlow,
      question1: [true, chatSartingQuestions[0].message],
      question2: [true, chatSartingQuestions[1].message],
    }))
    setHasShownHistory(defaultState.hasShownHistory)
    setUserData({uuid: userData.uuid}) // set to just be uuid
    getUserSession(userData.uuid, userData.current_session_id) // get sesion so that we have it
  }

  const loadUserData = async (data1) => {
    const data = await getUserSession(data1.uuid, data1.current_session_id)
    let data2 = {}
    if (data.error) { // for other errors, redirect to sign in for proper session
      console.log("What are errors?", data.error)
      if (window.location.pathname != '/register') {
        // window.location.replace('/register')
      }
    } else if (data.uuidValid == false) {
      data2 = {requireSignIn: true, uuidHasAccount: true}
    } else {
      if (data.user_id != null) { // returned a user_id becasue active session
        data2 = data
      } else { // return if uuid has account
        data2 = {uuidHasAccount: data.uuidHasAccount}
      }
    }
    setUserData({...userData, ...data1, ...data2})
  }

  // loading messages happens after, setLoadingUserData to false once loadingUserMessages is false
  useEffect(() => {
    if (!loadingUserMessages && loadingUserData) {
      setLoadingUserData(false)
    }
  }, [loadingUserMessages, loadingUserData])



  const appProps = {
    userData: userData, //object
    setUserData: setUserData, //function
    loadingUserData: loadingUserData,
    setLoadingUserData: setLoadingUserData,
    currentMessages: currentMessages, //object
    setCurrentMessages: setCurrentMessages, //function
    requestClearChatMessageHistory: requestClearChatMessageHistory,//function
    termsOfUseOpen: termsOfUseOpen,
    setTermsOfUseOpen: setTermsOfUseOpen,
    privacyPolicyOpen: privacyPolicyOpen,
    setPrivacyPolicyOpen: setPrivacyPolicyOpen,
    accountModalOpen: accountModalOpen,
    setAccountModalOpen: setAccountModalOpen,
    clearStateData: clearStateData,
    disclosureOpen: disclosureOpen,
    setDisclosureOpen: setDisclosureOpen,
    responseFlow: responseFlow,
    setResponseFlow: setResponseFlow,
    setMessageThreads, setMessageThreads,
    allowSetShit: allowSetShit, //rename or remove?
    setAllowSetShit: setAllowSetShit, //rename or remove?
    testVar: testVar, //rename or remove?
    setTestVar: setTestVar, //rename or remove?
    setQuestionButton: setQuestionButton,
    inputPlaceholderText: inputPlaceholderText,
    setInputPlaceholderText: setInputPlaceholderText,
    messageThreads: messageThreads,
    hasShownHistory: hasShownHistory,
    setHasShownHistory: setHasShownHistory,
    hasAcceptedTerms: hasAcceptedTerms, 
    setHasAcceptedTerms: setHasAcceptedTerms,
    registerNavMessage: registerNavMessage,
    setRegisterNavMessage: setRegisterNavMessage,
    showChatShare: showChatShare,
    setShowChatShare: setShowChatShare,
    isNewUUID,
    showSignUpModal: showSignUpModal,
    setShowSignUpModal: setShowSignUpModal,
    showPaywallModal, setShowPaywallModal,
    loadingUserMessages: loadingUserMessages,
    setLoadingUserMessages: setLoadingUserMessages,
    watchlistData: watchlistData,
    setWatchlistData: setWatchlistData,
    lastRequestedUpdateWatchlist: lastRequestedUpdateWatchlist,
    setLastRequestedUpdateWatchlist: setLastRequestedUpdateWatchlist,
    isRefreshing: isRefreshing,
    setIsRefreshing: setIsRefreshing,
    hasErroredWatchlist: hasErroredWatchlist,
    setHasErroredWatchlist: setHasErroredWatchlist,
    paywallMessage: paywallMessage,
    setPaywallMessage: setPaywallMessage,
    showWatchlistBlueDot: showWatchlistBlueDot,
    setShowWatchlistBlueDot: setShowWatchlistBlueDot,
    showWatchlistFlicker: showWatchlistFlicker,
    setShowWatchlistFlicker: setShowWatchlistFlicker,
    showPaywallAfter: showPaywallAfter,
    setShowPaywallAfter: setShowPaywallAfter,
  }

  // useEffect(() => {
  //   if (userData && !loadingUserData && !!userData.user_id) {
  //     const refreshProps = {lastRequestedUpdateWatchlist, setLastRequestedUpdateWatchlist, setIsRefreshing, setWatchlistData, setHasErroredWatchlist}
  //     refreshWatchlist(refreshProps, null, false)
  //   }
  // }, [userData, loadingUserData])

  useEffect(() => {
    const cookies = new Cookies();
    let uuid_value = cookies.get('uuid')
    if (!uuid_value) {
      cookies.set('uuid', `${uuidv4()}`, { path: '/' });
      uuid_value = cookies.get('uuid')
      setIsNewUUID(true)
    } else {
      setIsNewUUID(false)
    }

    // This is the unique id for this browser tab for the websocket!
    let current_session_id = uuidv4()
    
    let data1 = {uuid: uuid_value, current_session_id: current_session_id}
    loadUserData(data1)
  }, [])

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/chat"/>} />
          <Route path="/terms-of-use" element={<TermsOfUsePage appProps={appProps} />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage appProps={appProps} />} />
          {/* <Route path="/faqs" element={<FAQsPage appProps={appProps} />} /> */}
          <Route path="/chat" element={<ChatPage appProps={appProps} />} />
          <Route path="/register" element={<RegisterPage appProps={appProps} />} />
          <Route path="/account" element={<AccountPage appProps={appProps} />} />
          <Route path="/priority" element={<PriorityPage appProps={appProps} />} />
          <Route path="/feedback" element={<FeedbackPage appProps={appProps} />} />
          <Route path="/contact" element={<ContactPage appProps={appProps} />} />
          <Route path="/confirm-email" element={<ConfirmEmail appProps={appProps} />} />
          <Route path="/email-confirmed" element={<EmailConfirmed appProps={appProps} />} />
          <Route path="/confirmation-failed" element={<ConfirmationFailed appProps={appProps} />} />
          <Route path="/forgot-password" element={<ForgotPassword appProps={appProps} />} />
          <Route path="/password-reset" element={<PasswordReset appProps={appProps} />} />
          <Route path="/unsubscribed" element={<Unsubscribed appProps={appProps} />} />
          <Route path="/plan-selection" element={<PlanSelectionV2 appProps={appProps} />} />
          <Route path='/*' element={ <ErrorPage appProps={appProps} />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
