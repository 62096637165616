import { ConsoleView } from "react-device-detect";

const getUserSession = async (uuid_value, current_session_id) => {
  try {
    const response = await fetch(`/get_session?uuid=${uuid_value}&current_session_id=${current_session_id}`);
    const data = await response.json();
    return data
  } catch (error) {
    console.log('session api errors:', error);
    return { error: "Sesion Error" };
  }
};

const userShared = (shareAnswer, shareHash, hasShared) => {
  let requestOptions
  if (!hasShared) {
    requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `mutation userSharedIncite($shareAnswer: Boolean!, $shareHash: String!) {
          userSharedIncite(shareAnswer: $shareAnswer, shareHash: $shareHash) {
            status
          }
        }`,
        variables: {
          shareAnswer: shareAnswer,
          shareHash: shareHash,
        },
      })
    };
  } else {
    // Now we set that they have shared
    requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `mutation setUserHasShared($hasShared: Boolean!) {
          setUserHasShared(hasShared: $hasShared) {
            status
          }
        }`,
        variables: {
          hasShared: hasShared,
        },
      })
    };
  }
  let final = fetch('/graphql', requestOptions)
    .then(response => response.json())
    .then(data => {
      if (data.errors && data.errors.length > 0) {
        console.log("share error?", data.errors)
        return "ERROR"
      } else {
        return data.data
      }
    })
    .catch(error => {
      console.log('api errors:', error)
      return "ERROR"
    })
  return final
}

const userRatedMessage = (vote) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `mutation userRatedMessage($vote: String!) {
        userRatedMessage(vote: $vote) {
          status
        }
      }`,
      variables: {
        vote: vote,
      },
    })
  };
  let final = fetch('/graphql', requestOptions)
    .then(response => response.json())
    .then(data => {
      if (data.errors && data.errors.length > 0) {
        console.log("user rating error?", data.errors)
        return "ERROR"
      } else {
        return data.data
      }
    })
    .catch(error => {
      console.log('api errors:', error)
      return "ERROR"
    })
  return final
}

const userShareExperienceButton = (buttonSelected) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `mutation userSetShareExperience($buttonSelected: String!) {
        userSetShareExperience(buttonSelected: $buttonSelected) {
          status
        }
      }`,
      variables: {
        buttonSelected: buttonSelected,
      },
    })
  };
  let final = fetch('/graphql', requestOptions)
    .then(response => response.json())
    .then(data => {
      if (data.errors && data.errors.length > 0) {
        console.log("user rating error?", data.errors)
        return "ERROR"
      } else {
        return data.data
      }
    })
    .catch(error => {
      console.log('api errors:', error)
      return "ERROR"
    })
  return final
}

const getWatchlistData = (setWatchlistData) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({query: `
      {
        userWatchlistData {
          id
          assetType
          name
          ticker
          exchangeCountry
          action
          change
          updatedAt
          currentPrice
          pricePctChange1d
          pricePctChange24hr
        }
      }`
    })
  };
  fetch('/graphql', requestOptions)
  .then(response => response.json())
  .then(data => {
    const responseData = data.data.userWatchlistData
    setWatchlistData({
      Stocks: responseData.filter((v) => v.assetType == "stocks").sort((a, b) => a.name.localeCompare(b.name)),
      Cryptos: responseData.filter((v) => v.assetType == "cryptos").sort((a, b) => a.name.localeCompare(b.name)),
      Forex: responseData.filter((v) => v.assetType == "forex").sort((a, b) => a.name.localeCompare(b.name)),
    })
  })
  .catch(error => console.error('Error fetching data:', error));
};

const updateUserWatchlist = (tickerIdsAdd, tickerIdRemove, setWatchlistData) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `mutation updateUserWatchlistData($tickerIdsAdd: [ID!], $tickerIdRemove: ID) {
        updateUserWatchlistData(tickerIdsAdd: $tickerIdsAdd, tickerIdRemove: $tickerIdRemove) {
          id
          assetType
          name
          ticker
          exchangeCountry
          action
          change
          updatedAt
          currentPrice
          pricePctChange1d
          pricePctChange24hr
        }
      }`,
      variables: {
        tickerIdsAdd: tickerIdsAdd,
        tickerIdRemove: tickerIdRemove,
      },
    })
  };
  fetch('/graphql', requestOptions)
  .then(response => response.json())
  .then(data => {
    const responseData = data.data.updateUserWatchlistData
    setWatchlistData({
      Stocks: responseData.filter((v) => v.assetType == "stocks").sort((a, b) => a.name.localeCompare(b.name)),
      Cryptos: responseData.filter((v) => v.assetType == "cryptos").sort((a, b) => a.name.localeCompare(b.name)),
      Forex: responseData.filter((v) => v.assetType == "forex").sort((a, b) => a.name.localeCompare(b.name)),
    })
  })
  .catch(error => console.error('Error fetching data:', error));
};

const updateWatchlistData = (tickerIdsUpdate, setWatchlistData, forceRefresh) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `mutation updateWatchlistData($tickerIdsUpdate: [ID!], $forceRefresh: Boolean!) {
        updateWatchlistData(tickerIdsUpdate: $tickerIdsUpdate, forceRefresh: $forceRefresh) {
          id
          assetType
          name
          ticker
          exchangeCountry
          action
          change
          updatedAt
          currentPrice
          pricePctChange1d
          pricePctChange24hr
        }
      }`,
      variables: {
        tickerIdsUpdate: tickerIdsUpdate,
        forceRefresh: forceRefresh,
      },
    })
  };
  return fetch('/graphql', requestOptions)
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(data => {
    if (data.errors) {
      console.error('GraphQL errors:', data.errors);
      throw new Error('Error in GraphQL response');
    }
    const responseData = data.data.updateWatchlistData

    setWatchlistData(prevData => {
      const map = new Map();
      // Fill map with the existing data
      ['Stocks', 'Cryptos', 'Forex'].forEach(type => {
        prevData[type]?.forEach(item => map.set(item.id, item));
      });
      // Update with new data, overriding where necessary
      responseData.forEach(item => {
        map.set(item.id, { ...map.get(item.id), ...item });
      });
      const updatedData = {
        Stocks: Array.from(map.values()).filter(item => item.assetType === "stocks").sort((a, b) => a.name.localeCompare(b.name)),
        Cryptos: Array.from(map.values()).filter(item => item.assetType === "cryptos").sort((a, b) => a.name.localeCompare(b.name)),
        Forex: Array.from(map.values()).filter(item => item.assetType === "forex").sort((a, b) => a.name.localeCompare(b.name)),
      };
      return updatedData;
    });

    return "success";
  })
  .catch(error => {
    console.error('Error fetching data:', error);
    return "error";
  });
};


const refreshWatchlist = async ({
  lastRequestedUpdateWatchlist, setLastRequestedUpdateWatchlist, setIsRefreshing, setWatchlistData, setHasErroredWatchlist
}, tickerIdsNullAction, tickerIdsOutdatedAction, forceRefresh) => {
  const currentTime = new Date().getTime();
  let tickerIdsUpdate = null
  if (tickerIdsNullAction != null && tickerIdsOutdatedAction != null) {
    tickerIdsUpdate =  tickerIdsNullAction.concat(tickerIdsOutdatedAction)
  }
  if (lastRequestedUpdateWatchlist !== null) {
    const diff = (currentTime - lastRequestedUpdateWatchlist)
    if (diff < 1000*0.5) { // If not at leat 30 seconds, don't request again.
      return
    }
  }
  setLastRequestedUpdateWatchlist(currentTime)
  setIsRefreshing(true)
  try {
    const result = await updateWatchlistData(tickerIdsUpdate, setWatchlistData, forceRefresh)
    setHasErroredWatchlist(result === "error");
  } catch (error) {
    console.error('Error during refresh:', error);
    setHasErroredWatchlist(true);
  } finally {
    setIsRefreshing(false);

  }
};

export { getUserSession, userShared, userRatedMessage, userShareExperienceButton, getWatchlistData, updateUserWatchlist, updateWatchlistData, refreshWatchlist }
