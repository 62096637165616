import { Box, Button, Link } from '@mui/material';
import { themeColors } from "../utils/constants";
import ModalCloseIcon from '../../javascript/images/ModalCloseIcon.png'
import MarketProLogo from '../../javascript/images/MarketProLogo.png'
import "../../stylesheets/chat_page.css"
import { useState, useEffect } from 'react';
import feedbackIncite from '../api/feedback'
import addSubscriptionCoupon from '../api/subscription'
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

const SubscriptionCancellationModal = ({ setShowSubCancelModal, getStripeSubscription }) => {

  const [opacity, setOpacity] = useState(0)
  const [feedback, setFeedback] = useState('')

  useEffect(() => {
    setOpacity(1.0)
  }, [])

  const handleCancel = async () => {
    try {
      await fetch(`/stripe_cancel`);
      getStripeSubscription() // make my life simpler, call again lol.
      setShowSubCancelModal(false)
    } catch (error) {
      console.log('session api errors:', error);
      return { error: "Sesion Error" };
    }
  }

  let classPlaceholderInput = 'feedback-input'

  return (
    <div className="modal modal-paywall" style={{
      opacity: opacity,
      transition: 'opacity 1.0s',
      }}>
      <div className="paywall-modal-content" style={{}}>

        <Box sx={{position: 'absolute', top: '20px', left: '20px', '&:hover': {cursor: 'pointer'}}} onClick={() => setShowSubCancelModal(false)}>
          <img src={ModalCloseIcon} style={{height: '16px', width: '16px'}}/> 
        </Box>

        <Box className="base-text" sx={{gap: '0px', marginBottom: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontWeight: '500', fontSize: '36px'}}>
          <img src={MarketProLogo} style={{width: '32px'}} className="logo-img" alt="IncitePlus Logo" />
          <Box className="header-paywall-modal" sx={{fontWeight: '600', color: '#1D1D1F'}}>
            Market Pro
          </Box>
        </Box>

        <Box className="base-text" sx={{margin: '16px auto 0px auto', width: '84%', textAlign: 'left', fontWeight: '500', fontSize: '14px', lineHeight: '133%',}}>
          Before you go...
          <br/><br/>
          We are working on constantly improving Incite. If you have any feedback, please let us know below.
        </Box>


          <Box sx={{margin: '24px auto 20px auto', width: '86%', position: 'relative', 
            height: '160px',
            }}>
            <textarea
              style={{
                fontSize: '13px',
                height: '100%',
                position: 'absolute',
                left: '0px', right: '0px',
                backgroundColor: '#ccc',
              }}
              className={classPlaceholderInput}
              value={feedback}
              onChange={e => {
                setFeedback(e.target.value)
              }}
              placeholder={'Your thoughts..'}
            />
          </Box>

        <Box className="base-text" sx={{color: 'red', margin: '36px auto 12px auto', fontWeight: '500', fontSize: '15px', lineHeight: '150%',}}>
          Are you sure you want to cancel?
        </Box>
        
        <Box sx={{width: '260px', margin: '0px auto', maxWidth: '86%', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Button variant="contained" className="i_understand_button" sx={{
            marginTop: '20px',
            textTransform: 'none',
            borderRadius: '24px',
            background: themeColors.buttonColor,
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
            width: '220px',
            maxWidth: '100%',
            textAlign: 'center',
            '&.Mui-disabled': {
              backgroundColor: '#696969',
            },
          }} onClick={() => {
            if (true){//feedback.length > 0) { // Still send feedback if they typed but decided to stay
              let modifiedFeedback = "** USER WROTE FEEDBACK, didn't cancel (either accident or decided to keep on paywall) **\n"+feedback
              feedbackIncite(modifiedFeedback, "subscription cancel")
            }
            setShowSubCancelModal(false)
          }}>
            <Box className="base-text i_understand_text" sx={{
              display: 'flex',
              fontSize: '14px',
              fontWeight: 500, alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: '1px',
              color: '#F2F2F2',
            }}>
              Continue Using
            </Box>
          </Button>
        </Box>
        
        <Box sx={{marginTop: '32px'}} onClick={() => {
          if (true){//feedback.length > 0) {
            let modifiedFeedback = "** USER CANCELLING **\n"+feedback
            feedbackIncite(modifiedFeedback, "subscription cancel")
          }
          handleCancel()
        }}>
        <Link className="base-text" sx={{color: 'black', '&:hover': {cursor: 'pointer'} }}>Cancel</Link>
        </Box>

      </div>
    </div>
  )
}

const SubscriptionCancellationCoupon = ({ setShowSubCancelModal, setShowSubCancelOfferModal }) => {
  const [opacity, setOpacity] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setOpacity(1.0)
  }, [])

  return (
    <div className="modal modal-paywall" style={{
      opacity: opacity,
      transition: 'opacity 1.0s',
      }}>
      <div className="paywall-modal-content" style={{width: '500px', maxWidth: '100%'}}>

        <Box className="base-text" sx={{margin: '16px auto 0px auto', width: '90%', color: '#7F7F83', textAlign: 'center', fontWeight: '500', fontSize: '14px', lineHeight: '150%',}}>
          <Box sx={{color: '#262626', fontWeight: 'bold', fontSize: '16px'}}>
            Continue your membership and get 50% off!
          </Box>
          <br/>
          Keep unlimited access and get 50% off your next
          <br/>
          3 months of membership renewal.
          <br/><br/>
        </Box>

        <Box sx={{width: '260px', margin: '0px auto', maxWidth: '86%', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Button variant="contained" className="i_understand_button" sx={{
            marginTop: '20px',
            textTransform: 'none',
            borderRadius: '24px',
            background: themeColors.buttonColor,
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
            width: '320px',
            maxWidth: '100%',
            textAlign: 'center',
            '&.Mui-disabled': {
              backgroundColor: '#696969',
            },
          }} 
          onClick={async () => {
            setLoading(true)
            let modifiedFeedback = "** Subscription Saved with 50% Coupon! **\n"
            feedbackIncite(modifiedFeedback, "subscription saved")
            const result = await addSubscriptionCoupon();
            if (result !== "ERROR") {
              setShowSubCancelOfferModal("accepted");
            }
            setLoading(false)
          }}>
            <Box className="base-text i_understand_text" sx={{
              display: 'flex',
              fontSize: '14px',
              fontWeight: 500, alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: '1px',
              color: '#F2F2F2',
            }}>
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Keep Unlimited Access"
              )}
            </Box>
          </Button>
        </Box>
        
        <Box sx={{marginTop: '32px'}} onClick={() => {
          setShowSubCancelOfferModal("declined")
          setShowSubCancelModal(true)
        }}>
        <Link className="base-text" sx={{color: '#7F7F83', textDecoration: 'none', '&:hover': {cursor: 'pointer'} }}>Cancel unlimited</Link>
        </Box>

      </div>
    </div>
  )
}

const SubscriptionOfferCoupon = ({ setShowSubOfferModal }) => {
  const [opacity, setOpacity] = useState(0)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setOpacity(1.0)
  }, [])

  return (
    <div className="modal modal-paywall" style={{
      opacity: opacity,
      transition: 'opacity 1.0s',
      }}>
      <div className="paywall-modal-content" style={{width: '500px', maxWidth: '100%'}}>

        <Box className="base-text" sx={{margin: '16px auto 0px auto', width: '90%', color: '#7F7F83', textAlign: 'center', fontWeight: '500', fontSize: '14px', lineHeight: '150%',}}>
          <Box sx={{color: '#262626', fontWeight: 'bold', fontSize: '16px'}}>
            Begin membership and get 50% off!
          </Box>
          <br/>
          Start unlimited access and get 50% off your next
          <br/>
          3 months of Market Pro.
          <br/><br/>
        </Box>

        <Box sx={{width: '260px', margin: '0px auto', maxWidth: '86%', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Button variant="contained" className="i_understand_button" sx={{
            marginTop: '20px',
            textTransform: 'none',
            borderRadius: '24px',
            background: themeColors.buttonColor,
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
            width: '320px',
            maxWidth: '100%',
            textAlign: 'center',
            '&.Mui-disabled': {
              backgroundColor: '#696969',
            },
          }} 
          onClick={async () => {
            navigate('/plan-selection?offer=monthly-market-pro')
          }}>
            <Box className="base-text i_understand_text" sx={{
              display: 'flex',
              fontSize: '14px',
              fontWeight: 500, alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: '1px',
              color: '#F2F2F2',
            }}>
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Claim Offer"
              )}
            </Box>
          </Button>
        </Box>
        
        <Box sx={{marginTop: '32px'}} onClick={() => {
          setShowSubOfferModal("declined")
        }}>
        <Link className="base-text" sx={{color: '#7F7F83', textDecoration: 'none', '&:hover': {cursor: 'pointer'} }}>no thanks</Link>
        </Box>

      </div>
    </div>
  )
}

export { SubscriptionCancellationModal, SubscriptionCancellationCoupon, SubscriptionOfferCoupon }
