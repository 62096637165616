import React from "react";
import { Box } from "@mui/system";
import { isDarkMode } from "../../utils/constants";

const formatLargeNumbers = (number) => {
  const roundDigits = 0;
  const absNumber = Math.abs(number);
  let formattedNumber;

  if (absNumber >= 1_000_000_000_000) {
    formattedNumber = (absNumber / 1_000_000_000_000).toFixed(roundDigits) + 'T';
  } else if (absNumber >= 1_000_000_000) {
    formattedNumber = (absNumber / 1_000_000_000).toFixed(roundDigits) + 'B';
  } else if (absNumber >= 1_000_000) {
    formattedNumber = (absNumber / 1_000_000).toFixed(roundDigits) + 'M';
  } else if (absNumber >= 1_000) {
    formattedNumber = (absNumber / 1_000).toFixed(roundDigits) + 'K';
  } else {
    formattedNumber = absNumber.toString();
  }

  return (number < 0 ? '-' : '') + formattedNumber;
};

const RenderResponseString = ({ messageThread }) => {

  let concatString = ""
  if (messageThread.message) {
    if (messageThread.advancedFilteringMessage != undefined) {
      concatString = messageThread.message.concat('', messageThread.advancedFilteringMessage)
    } else {
      concatString = messageThread.message
    }
  }

  let prHeader = false;
  let isHeader = false;

  return (
    <Box sx={{lineHeight: '172%', fontWeight: isDarkMode ? 300 : 'unset'}}>
      {concatString.split('\n').map((str, idx) => {
        // Convert Markdown-like ### Headings to <h3>
        let formattedStr = str.trim(); // Start with the trimmed string
        let extraString = ""
        
        prHeader = isHeader;
        // Check for headers and adjust accordingly
        if (formattedStr.startsWith("####")) {
          let endIdx = formattedStr.indexOf("####", 4);
          if (endIdx !== -1) {
            extraString = formattedStr.substring(endIdx+4)
            formattedStr = formattedStr.substring(4, endIdx);
          } else {
            formattedStr = formattedStr.substring(4);
          }
          formattedStr = `<h4>${formattedStr.trim()}</h4>`;
          formattedStr = formattedStr + extraString
          isHeader = true;
        } else if (formattedStr.startsWith("###")) {
          let endIdx = formattedStr.indexOf("###", 3); // Find the index of the next "###" after the first
          if (endIdx !== -1) {
            extraString = formattedStr.substring(endIdx+3)
            formattedStr = formattedStr.substring(3, endIdx); // Extract the text between
            
          } else {
            formattedStr = formattedStr.substring(3); // Or just remove the first "###" if no closing "###" found
          }
          formattedStr = `<h3>${formattedStr.trim()}</h3>`; // Wrap in <h3> and trim any extra whitespace
          formattedStr = formattedStr + extraString
          isHeader = true;
        } else {
          isHeader = false;
        }
        
          // Apply formatting for bold and italic text
          formattedStr = formattedStr
            .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold
            .replace(/\*(.*?)\*/g, '<em>$1</em>'); // Italic
        
        // Render the formatted string with appropriate HTML handling
        return (
          <React.Fragment key={idx}>
            {/* {idx !== 0 && <br />} */}
            {idx !== 0 && !isHeader && !prHeader && <br />}
            {/* We're using dangerouslySetInnerHTML because we're sure the content is safe */}
            <span dangerouslySetInnerHTML={{ __html: formattedStr }} />
          </React.Fragment>
        );
      })}
    </Box>
  )
}


export { formatLargeNumbers, RenderResponseString }