import { Box, Button, TextField, Menu } from '@mui/material';
import { Navbar } from "../components/Navbar";
import { isDarkMode, themeColors } from '../utils/constants';
import Back from '../components/Reusable/Back';
import IncitePlus from '../../javascript/images/IncitePlus.png'
import '../../stylesheets/index.css'
import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { pofessionsList, customSelectTheme } from '../utils/constants';
import MenuItem from '@mui/material/MenuItem';
import { ThemeProvider } from '@mui/material/styles';
import CountrySelector from '../utils/CountrySelector';
import { SubscriptionCancellationModal, SubscriptionCancellationCoupon } from '../components/SubscriptionCancellationModal';
import WatchlistLine from '../../javascript/images/WatchlistLine.png'
import EXCHANGE_COUNTRY_CODE_MAPPING from '../utils/CountryExchanges';
import { useNavigate } from "react-router-dom"
import feedbackIncite from '../api/feedback';

const AccountPage = (props) => {
  const {
    appProps
  } = props

  const { userData , setUserData} = appProps

  const [accountParams, setAccountParams] = useState({
    full_name: "",
    username: "",
    country: "",
    selected_profession: "", 
    selected_country_exchange: "",
  })

  const [otherProfession, setOtherProfession] = useState('');
  const [allowSubmit, setAllowSubmit] = useState(false)
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState(null)
  const [pendingCancel, setPendingCancel] = useState(null)
  const [isCompSubscription, setIsCompSubscription] = useState(false)

  useEffect(() => {
    if (userData.user_account_info) {
      let profession = userData.user_account_info.selected_profession
      if (profession && profession.slice(0,5) == "Other") {
        setOtherProfession(profession.split(' - ')[1])
        profession = "Other"
      }

      setAccountParams({
        full_name: userData.user_account_info.full_name,
        username: userData.user_account_info.username,
        country: userData.user_account_info.country,
        selected_profession: profession,//userData.user_account_info.selected_profession,
        selected_country_exchange: userData.user_account_info.selected_country_exchange,
      })
    }
  }, [userData])
  
  const handleChange = (event) => {
    setAllowSubmit(true)
    const {name, value} = event.target
    setAccountParams(accountParams => ({
      ...accountParams,
      [name]: value,
    }))
  };

  const handleOtherChange = (event) => {
    setAllowSubmit(true)
    setOtherProfession(event.target.value);
  };


  const handleAccept = () => {
    if (isLoading) { 
      return
    }
    setIsLoading(true); 

    // setAboutUserModal(false); // Hide modal
    const userProfession = accountParams.selected_profession === 'Other' ? `Other - ${otherProfession}` : accountParams.selected_profession
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `mutation ($fullName: String!, $username: String!, $country: String!, $selectedProfession: String!, $countryExchange: String!) {
          updateUserAccountData(fullName: $fullName, username: $username, country: $country, selectedProfession: $selectedProfession, countryExchange: $countryExchange) {
            status
          }
        }`,
        variables: {
          fullName: accountParams.full_name,
          username: accountParams.username,
          country: accountParams.country,
          selectedProfession: userProfession,
          countryExchange: accountParams.selected_country_exchange,
        },
      })
    };
  
    try {
      let final = fetch('/graphql', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.errors && data.errors[0]) {
          setErrors(JSON.parse(data.errors[0].message))
        } else {
          setUserData({
            ...userData, 
            user_account_info: {
              ...userData.user_account_info,
              country: accountParams.country,
              full_name: accountParams.full_name,
              selected_profession: userProfession,
              username: accountParams.username,
              selected_country_exchange: accountParams.selected_country_exchange,
            }
          })
          setAllowSubmit(false)
          setErrors([])
        }
        setIsLoading(false)
        return data.data;
      })
    } catch(e) {
      console.log("What was the error?", e)
      setAllowSubmit(false)
      setIsLoading(false)
    }
  };


  return (
    // <ThemeProvider theme={customSelectTheme}>
    <Box sx={{width: '100vw', height: '100vh', backgroundColor: themeColors.backgroundColor}}>
      <Box sx={{position: 'fixed', left: '0px', top: '0px', width: '100%'}}>
        <Navbar appProps={appProps} headerDescription={'Account'}/>
        <Back value={false}/>
      </Box>
      <Box sx={{width: '100%', height: '100%', display: 'flex', overflowY: 'auto'}}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          marginTop: '100px',
          width: '80%',
          maxWidth: '600px',
          // alignItems: 'center',
          paddingBottom: '30px',
        }}>
          { (userData.user_account_info && userData.user_account_info.email) &&
          <Box className="account-page-text" sx={{color: themeColors.color, width: '100%'}}>
            <p className="account-page-text" style={{color: themeColors.color, fontWeight: 500}}>
            Account Details <i style={{color: 'gray', fontSize: '14px'}}>({userData.user_account_info.email})</i>
            </p>
            <br/>
          </Box>
          }
          
          <TextField 
            sx={textFieldStyles}
            inputProps={{ required: true}} 
            InputLabelProps={{style: {fontFamily: 'Montserrat', fontWeight: '400', color: themeColors.color}}} 
            autoComplete="off"
            value={accountParams.full_name}
            onChange={handleChange}
            name="full_name"
            label="Full Name"
          />

          <TextField 
            sx={textFieldStyles}
            inputProps={{ required: true}} 
            InputLabelProps={{style: {fontFamily: 'Montserrat', fontWeight: '400', color: themeColors.color}}} 
            autoComplete="off"
            value={accountParams.username}
            onChange={handleChange}
            name="username"
            label="Username"
          />

          {/* <TextField 
            sx={textFieldStyles}
            inputProps={{ required: true}} 
            InputLabelProps={{style: {fontFamily: 'Montserrat', fontWeight: '400', color: themeColors.color}}} 
            autoComplete="off"
            value={accountParams.country}
            onChange={handleChange}
            name="country"
            label="Country"
          /> */}
          <ThemeProvider theme={customSelectTheme}>
          <Box sx={{marginTop: '0px', marginBottom: '20px'}}>
            
            <FormControl fullWidth>
              <CountrySelector 
                country={accountParams.country} 
                setCountry={val => {
                  setAllowSubmit(true)
                  setAccountParams(accountParams => ({
                    ...accountParams,
                    "country": val,
                  }))
                }}
              />
            </FormControl>
          </Box>

          <Box sx={{marginTop: '0px', marginBottom: '20px'}}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Exchange Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={accountParams.selected_country_exchange}
                label="CountryExchange"
                onChange={e => {
                  setAllowSubmit(true)
                  setAccountParams(accountParams => ({
                    ...accountParams,
                    "selected_country_exchange": e.target.value,
                  }))
                }}
                style={{color: 'white'}}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: 'rgba(55,55,55)',
                    },
                  },
                }}
              >
                {Object.keys(EXCHANGE_COUNTRY_CODE_MAPPING).map((k) => (
                  <MenuItem key={k} value={k} style={{backgroundColor: 'rgba(55,55,55)', color: 'white'}}>
                    {k} ({EXCHANGE_COUNTRY_CODE_MAPPING[k]})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Profession</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={accountParams.selected_profession}
              label="Profession"
              onChange={e => {
                setAllowSubmit(true)
                if(e.target.value !== 'Other') {
                  setOtherProfession('');
                }
                setAccountParams(accountParams => ({
                  ...accountParams,
                  "selected_profession": e.target.value,
                }))
              }}
              style={{color: themeColors.color}}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: 'rgba(55,55,55)',
                  },
                },
              }}
            >
              {pofessionsList.map((prof) => (
                <MenuItem key={prof} value={prof} style={{backgroundColor: 'rgba(55,55,55)', color: 'white'}}>
                  {prof}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </ThemeProvider>

          {accountParams.selected_profession === 'Other' && (
            <TextField
              label="Specify Profession"
              value={otherProfession}
              onChange={handleOtherChange}
              fullWidth
              sx = {professionTextStyles}
            />
          )}

          { (errors.length > 0) ?
            <Box sx={{color: 'rgb(255,0,0,.8)', marginBottom: '10px'}}>
              <ul>{errors.map((error) => {
                return <li key={error}>{error}</li>
                })}
              </ul>
            </Box>
            :
            <Box sx={{marginBottom: '12px'}}></Box>
          }
          
          <Button variant="contained" className="i_understand_button" sx={{
            marginTop: '10px',
            textTransform: 'none',
            borderRadius: '24px',
            background: themeColors.buttonColor,
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
            width: '200px',
            maxWidth: '100%',
            textAlign: 'center',
            '&.Mui-disabled': {
              backgroundColor: '#696969',
            },
          }} 
          disabled={!allowSubmit}
          onClick={() => {
            if (!accountParams.selected_profession || (accountParams.selected_profession === 'Other' && !otherProfession)) {
              setErrors(["Other profession can't be blank"])
            } else {
              handleAccept()
            }
          }}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />  // Show spinner when loading
            ) : (
            <Box className="base-text i_understand_text" sx={{
              display: 'flex',
              fontWeight: 500, alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: '2px',
              color: '#F2F2F2',
            }}>
              Update
            </Box>
            )}
          </Button>

        { (userData && userData.has_active_subscription) &&
          <SubscriptionControls pendingCancel={pendingCancel} setPendingCancel={setPendingCancel} isCompSubscription={isCompSubscription} setIsCompSubscription={setIsCompSubscription}/>
        }

        <Box className="account-page-text"
          sx={{color: themeColors.color, width: '100%', marginTop: '20px'}}
          >
          <p className="account-page-text" style={{color: themeColors.color, fontSize: '12px', fontWeight: 500}}>
          For all other inquiries, please contact help@inciteai.com
          <br/><br/>
          </p>
        </Box>

          <DeleteAccountWithConfirmation appProps={appProps} pendingCancel={pendingCancel} isCompSubscription={isCompSubscription}/>
          <Box sx={{height: '70px'}} />
        </Box>
      </Box>
    </Box>
    // {/* </ThemeProvider> */}
  )
}
export default AccountPage

const SubscriptionDetails = () => {

  return (
    <Box sx={{marginTop: '10px', marginBottom: '120px'}}>
      <img src={IncitePlus} style={{width: '200px'}} />
      <Box className="account-page-text" sx={{color: themeColors.color, width: '100%', marginTop: '10px'}}>
        <p className="account-page-text" style={{color: themeColors.color, fontSize: '14px', fontWeight: 500}}>
        Contact help@inciteai.com to cancel or modify your subscription
        <br/><br/>
        </p>
      </Box>
    </Box>
  )

}

const SubscriptionControls = ({ pendingCancel, setPendingCancel, isCompSubscription, setIsCompSubscription }) => {
  const [showSubCancelModal, setShowSubCancelModal] = useState(false)
  const [showSubCancelOfferModal, setShowSubCancelOfferModal] = useState(null) // starting as null, so when false I trigger check subscription again!
  const [planInterval, setPlanInterval]  = useState(null)
  const [accessExpiresIn, setAccessExpiresIn]  = useState(null)
  const [planPrice, setPlanPrice]  = useState(null)
  const [discountCoupon, setDiscountCoupon]  = useState(null)
  const [renewalDate, setRenewalDate] = useState(null)
  const [loading, setLoading] = useState(true)

  const getStripeSubscription = async () => {
    setLoading(true)
    try {
      const response = await fetch(`/stripe_subscription_status`);
      const data = await response.json();
      setLoading(false)
      setPlanInterval(data.plan_interval)
      setAccessExpiresIn(data.access_expires_in)
      setPlanPrice(data.plan_price)
      setDiscountCoupon(data.discount_coupon)
      setRenewalDate(data.renewal_date)
      setIsCompSubscription(data.is_comp_subscription)
      if (data.canceled_at) {
        setPendingCancel(true)
      } else {
        setPendingCancel(false)
      }
    } catch (error) {
      console.log('session api errors:', error);
      return { error: "Sesion Error" };
    }
  }

  useEffect(() => {
    if (showSubCancelOfferModal == "accepted") { // not the null starting state, but when I close modal and it's false, we check again.
      getStripeSubscription() // and this is only called once the request happens.
    }
  }, [showSubCancelOfferModal])

  useEffect(() => {
    getStripeSubscription()
  }, []);

  const handleReactivate = async () => {
    try {
      await fetch(`/stripe_reactivate`);
      let adminMessage = "$$$ USER REACTIVATED SUBSCRIPTION $$$"
      feedbackIncite(adminMessage, "subscription reactivate")
      getStripeSubscription() // make my life simpler, call again lol.
    } catch (error) {
      console.log('session api errors:', error);
      return { error: "Sesion Error" };
    }
  }

  if (loading) {
    return (
      <Box sx={{marginTop: '30px'}}>
        <CircularProgress/>
      </Box>
    )
  }

  if (isCompSubscription) {
    return (
      <div style={{marginTop: '40px'}}>
        <Box sx={{width: '100%'}}>
          <img src={WatchlistLine} style={{maxWidth: '100%'}}/>
        </Box>
        <Box className="account-page-text" sx={{marginTop: '14px', marginBottom: '4px', color: themeColors.color, fontSize: '13px', fontWeight: 500}}>
          You are subscribed to Incite Market Pro
        </Box>
      </div>
    )
  }

  if (accessExpiresIn != null) { // accessExpiresIn can be 0 hours until task runs
    return (
      <div style={{marginTop: '40px'}}>
        <Box sx={{width: '100%'}}>
          <img src={WatchlistLine} style={{maxWidth: '100%'}}/>
        </Box>
        <Box className="account-page-text" sx={{marginTop: '14px', marginBottom: '4px', color: themeColors.color, fontSize: '13px', fontWeight: 500}}>
          You are have an Incite Market Pro Day Pass. Unlimited Access Expires in {accessExpiresIn} hours.
        </Box>
      </div>
    )
  }

  return (
    <div style={{marginTop: '40px'}}>

      <Box sx={{width: '100%'}}>
        <img src={WatchlistLine} style={{maxWidth: '100%'}}/>
      </Box>

      { showSubCancelModal &&
        <SubscriptionCancellationModal setShowSubCancelModal={setShowSubCancelModal} getStripeSubscription={getStripeSubscription}/>
      }
      { showSubCancelOfferModal == "offering" &&
        <SubscriptionCancellationCoupon setShowSubCancelModal={setShowSubCancelModal} setShowSubCancelOfferModal={setShowSubCancelOfferModal}/>
      }

      <Box className="account-page-text" sx={{marginTop: '14px', marginBottom: '4px', color: themeColors.color, fontSize: '13px', fontWeight: 500}}>
        You are subscribed to Incite Market Pro {planInterval} for ${planPrice} { discountCoupon && ` (${discountCoupon}% off coupon applied)`}
      </Box>

      { pendingCancel ?
        <Box className="account-page-text" sx={{color: 'red', fontSize: '13px', fontWeight: 500}}>
          <i>Subscription will expire on {renewalDate}</i>
        </Box>
      :
        <Box className="account-page-text" sx={{color: themeColors.color, fontSize: '13px', fontWeight: 500}}>
          <i>Subscription will auto-renew on {renewalDate}</i>
        </Box>
      }

      <Button variant="contained" className="i_understand_button" sx={{
        marginTop: '16px',
        textTransform: 'none',
        borderRadius: '24px',
        background: pendingCancel ? themeColors.buttonColor : '#696969',
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
        width: pendingCancel ? '230px':'180px',
        height: pendingCancel ? '50px':'38px',
        maxWidth: '100%',
        textAlign: 'center',
        '&.Mui-disabled': {
          backgroundColor: '#696969',
        },
      }}
      onClick={() => {
        if (pendingCancel) {
          handleReactivate()
        } else {
          //  First check if they are a monthly!! (Doesn't apply for annual)
          if (planInterval == "Monthly" && discountCoupon == null) {
            setShowSubCancelOfferModal("offering") // there are 4 states, null (don't show), offering (show), then after, "acepted" or "declined"
          } else {
            setShowSubCancelModal(true)
          }
        }
      }}
      >
        <Box className="base-text i_understand_text" sx={{
          display: 'flex',
          fontSize: pendingCancel ? '14px':'12px',
          fontWeight: 500, alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          marginTop: '1px',
          color: '#F2F2F2',
        }}>
          {pendingCancel ? 'Reactivate Subscription' : 'Cancel Subscription'}
        </Box>
      </Button>
    </div>
  );
};


const DeleteAccountWithConfirmation = ({ appProps, pendingCancel, isCompSubscription }) => {
  const { userData } = appProps
  const [anchorEl, setAnchorEl] = useState(null);
  const [password, setPassword] = useState('');
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState(null)
  const [loading, setLoading] = useState(false)
  const [requirePassword, setRequirePassword] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const navigate = useNavigate()

  // Handle dropdown open
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle dropdown close
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle password input change
  const handlePasswordChange = (event) => {
    const inputPassword = event.target.value;
    setPassword(inputPassword);
    // Enable submit button only if password is entered
    setAllowSubmit(inputPassword.length > 0);
  };

  useEffect(() => {
    if (userData && userData.has_active_subscription && !pendingCancel && !isCompSubscription) {
      setErrorMessage("You cannot delete your account while you still have an active subscription.")
    } else {
      setErrorMessage(null)
    }
  }, [pendingCancel, isCompSubscription, userData])

  useEffect(() => {
    setRequirePassword(userData && userData.user_account_info && userData.user_account_info.login_method == "email")
  }, [userData])
  

  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleClick}
        // disabled={!pendingCancel}
        sx={{
          textTransform: 'none',
          borderRadius: '24px',
          border: '2px solid rgba(100,0,0,0.7)',
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
          height: '36px',
          width: '150px',
          maxWidth: '100%',
          textAlign: 'center',
          backgroundColor: themeColors.backgroundColor,
          color: themeColors.color,
        }}
      >
        Delete Account
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          border: `3px solid ${themeColors.color}`
        }}
      >
        <Box sx={{paddingLeft: '12px', paddingRight: '10px', maxWidth: '340px'}}>
          
          <Box className="base-text i_understand_text" sx={{margin: '20px auto', fontSize: '14px', color: 'rgb(255,0,0,.6)'}}>
            {errorMessage}
          </Box>

          <Box className="base-text i_understand_text" sx={{fontSize: '14px', marginBottom: '10px'}}>
            { requirePassword &&
              <>
                Confirm your password to proceed:
                <br/><br/>
                <TextField
                  type="password"
                  label="Current Password"
                  variant="outlined"
                  value={password}
                  onChange={handlePasswordChange}
                  sx={{
                    marginBottom: '10px',
                    width: '100%',
                  }}
                />
              </>
            }
          </Box>

          <Box className="base-text i_understand_text" sx={{fontSize: '12px', marginBottom: '10px'}}>
            If you delete your account, all data and messages will be permanently removed.
            <br/><br/>
            Please note that you current receive daily complementary messages without paid subscription
          </Box>

          { !requirePassword &&
            <Box className="base-text i_understand_text" sx={{fontSize: '12px', marginBottom: '12px'}}>
              <input 
                type="checkbox"
                checked={confirmDelete}
                onChange={(e) => setConfirmDelete(e.target.checked)}
              /> I understand.
            </Box>
          }

          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              borderRadius: '24px',
              border: '2px solid rgba(100,0,0,0.7)',
              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
              height: '36px',
              width: '150px',
              maxWidth: '100%',
              textAlign: 'center',
              backgroundColor: themeColors.backgroundColor,
            }}
            
            disabled={!requirePassword && !confirmDelete}
            onClick={() => {
              setLoading(true)
              let user = {
                email: userData.user_account_info.email,
                password: password,
              }
              
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(user)
              } 
              fetch('/delete-user-account', requestOptions)
                .then(response => {
                  return (response.json())
                })
                .then(data => {
                  if (data.error_message) {
                    setErrorMessage(data.error_message)
                  } else {
                    window.alert("Your account has been permanently deleted.");
                    appProps.clearStateData()
                    navigate('/chat')
                  }
                  setLoading(false)
                })
                .catch(error => {
                  setErrorMessage(error[0])
                  setLoading(false)
                })
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />  // Show spinner when loading
            ) : (
            <Box
              className="base-text i_understand_text"
              sx={{
                display: 'flex',
                fontWeight: 500,
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                color: themeColors.color,
                fontSize: '13px',
              }}
            >
              Delete Account
            </Box>
            )}
          </Button>

        </Box>
      </Menu>
    </Box>
  );
};




const textFieldStyles = {
  marginBottom: '15px',
  '& .MuiInputBase-input': { // This targets the input itself
    color: themeColors.color,
  },
  '& .MuiFormLabel-root': { // This targets the label
    color: themeColors.color,
  },
  '& .MuiOutlinedInput-root': { // This targets the root of the input and the border color
    color: themeColors.color,
    '& fieldset': {
      borderColor: themeColors.color,
    },
    '&:hover fieldset': {
      borderColor: themeColors.color,
    },
    '&.Mui-focused fieldset': {
      borderColor: themeColors.color,
    },
  },
}

const professionTextStyles = {
  marginTop: '15px',
  '& .MuiInputBase-input': { // This targets the input itself
    color: themeColors.color,//'white',
  },
  '& .MuiFormLabel-root': { // This targets the label
    color: themeColors.color,//'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: themeColors.color,//'white',
    },
    '&:hover fieldset': {
      borderColor: themeColors.color,//'white',
    },
  }
}
