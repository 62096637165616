import { Box, Tooltip } from "@mui/material"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import '../../../stylesheets/index.css'
import '../../../stylesheets/chat_area.css'
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from "react";
import { isDarkMode, themeColors, requireLoginMessage } from '../../utils/constants'

const ShowHistory = (props) => {
  const {
    appProps,
    requestShowHistory
  } = props

  const {
    setRegisterNavMessage
  } = appProps
  
  return (
    <Box sx={{
      width: '100%', 
      marginBottom: '4px',
      marginTop: '0px',
      display: 'flex', alignItems: 'center', justifyContent: 'center'
      }}>
        <Tooltip title="Show History">
          <IconButton
            size="small" aria-haspopup="true"
            onClick={() => {
              setRegisterNavMessage(requireLoginMessage)
              requestShowHistory()
            }}
          >
            <Box sx={{
              padding: '4px 12px 0px 12px', borderRadius: '20px', 
              backgroundColor: themeColors.showHistoryContainerColor
            }}>
              <ArrowUpwardIcon sx={{fontSize: '18px', color: themeColors.showHistoryColor}}/>
            </Box>
          </IconButton>
        </Tooltip>
    </Box>
  )
}
export default ShowHistory

