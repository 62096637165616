
import React from 'react';

import { Box } from "@mui/material"
import { useState, useEffect } from "react";
import '../../../stylesheets/message.css'
import { isDarkMode } from "../../utils/constants";

const PrintOutText = ({ showDownArrowIcon, message, lineSpacing }) => {
  let lineWidths = lineSpacing//[303, 250, 114]
  let [showArrow, setShowArrow] = useState(0)
  useEffect(() => {
    setTimeout(() => {
      setShowArrow(.99)
    }, 2600)
  }, [])

  return (
    <Box className="chat_message_container" sx={{
      marginTop: '28px', width: '100%',// maxWidth: '380px'
      }}>
    <Box className="bubble_container" sx={{width: '100%', flexDirection: 'column', justifyContent: 'flex-end'}}>
      {message.split('\n').map((str, idx) => {
        return (
          <span key={`line-${idx}`}className="first-user-text" style={{
            color: isDarkMode ? '#fff' : '#1163D6',
            width: `${lineWidths[idx]}px`, margin: 'auto'}}>
          <React.Fragment key={idx}>
            {idx !== 0 && <br />}
            {str}
          </React.Fragment>
          </span>
        )
      })}
    </Box>
    { showDownArrowIcon &&
    <Box sx={{
      marginTop: '10px', marginBottom: '0px',
      opacity: showArrow,
      transition: 'opacity .7s',
      }}>
      {/* {isDarkMode ? 
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
          <path d="M6 16.2871L11.5 22L17 16.2871M11.5 6L11.5 21.84" stroke="url(#paint0_linear_1765_846)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <defs>
            <linearGradient id="paint0_linear_1765_846" x1="11.5" y1="22" x2="11.5" y2="6" gradientUnits="userSpaceOnUse">
              <stop stopColor="#1AA5C3"/>
              <stop offset="1" stopColor="#5390E6" stopOpacity="0.69"/>
            </linearGradient>
          </defs>
        </svg>
      :
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20" fill="none">
          <path d="M2 12.2871L7.5 18L13 12.2871M7.5 2L7.5 17.84" stroke="url(#paint0_linear_1765_849)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <defs>
            <linearGradient id="paint0_linear_1765_849" x1="7.5" y1="18" x2="7.5" y2="2" gradientUnits="userSpaceOnUse">
              <stop stopColor="#5390E6"/>
              <stop offset="1" stopColor="#293AD7" stopOpacity="0.69"/>
            </linearGradient>
          </defs>
        </svg>
      } */}
    </Box>
    }
    </Box>
  )
}

export default PrintOutText