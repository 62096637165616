import React from "react";
import { Box } from "@mui/material"
import { useEffect, useState, useRef } from "react"
import { ResponseWaiting, ResponseWaitingSecond, OnDemandWaiting } from "./ResponseWaiting";
import '../../../stylesheets/message.css'
import { isDarkMode, watchlistPaywallModalMessage, get_max_watchlist_items } from "../../utils/constants";
import { isMobileBrowser } from "../../utils/user_agent";
import { handleSharing } from "../../utils/share";
import RefreshDataDark from '../../images/RefreshDataDark.png'
import ShareButtonDark from '../../images/ShareButtonDark.png'
import RefreshData from '../../images/RefreshData.png'
import ShareButton from '../../images/ShareButton.png'
import WatchButtonDark from '../../images/WatchButtonDark.png'
import WatchButton from '../../images/WatchButton.png'
import PaywallSelectCheck from '../../images/PaywallSelectCheck.png'
import WatchlistUpdate from '../../images/WatchlistUpdate.png'
import { updateUserWatchlist } from '../../api/user'
import charting from './charting'
import { RenderResponseString } from './utils'

const ChatMessage = (props) => {
  const {
    idx, messageThread, loadingResponse, isLastMessage, showLoadingWaitingMessage, setShowLoadingWaitingMessage,
    setModalVisible, setShowChatShare, questionOnDemand,
    preventQuestionInput, setPreventQuestionInput, onQuestionSubmit, secondaryExperienceType,
    isLoggedIn, appProps
  } = props

  const { watchlistData, setShowWatchlistBlueDot, setShowWatchlistFlicker, userData, setUserData } = appProps
  const [showAddWatchlist, setShowAddWatchlist] = useState(false)
  const [showAddingWatchlist, setShowAddingWatchlist] = useState(false)
  const [showAddedatchlist, setShowAddedatchlist] = useState(false)
  const [countOfWatchlistTicker, setCountOfWatchlistTicker] = useState(null)
  const [watchlistCountError, setWatchlistCountError] = useState(false)
  const showLoadingWaitingMessageRef = useRef(showLoadingWaitingMessage);
  showLoadingWaitingMessageRef.current = showLoadingWaitingMessage;
  const questionOnDemandRef = useRef(questionOnDemand);
  questionOnDemandRef.current = questionOnDemand;
  const [hasRendered, setHasRendered] = useState(false)
  const isMobile = isMobileBrowser()

  useEffect(() => {
    if (isLastMessage && messageThread.answeredTickerIds && messageThread.answeredTickerIds.length > 0) {
      const ids = []
      Object.keys(watchlistData).forEach(k => watchlistData[k].forEach(v => ids.push(v.id)))
      let inList = false
      messageThread.answeredTickerIds.forEach(v => {
        if (ids.includes(v)) inList = true
      })
      if (!inList) setShowAddWatchlist(true)
      setCountOfWatchlistTicker(ids.length)
    }
  }, [isLastMessage, messageThread.answeredTickerIds, watchlistData])

  const chartContainerRef = charting({ idx, messageThread, hasRendered, isLastMessage, loadingResponse, showLoadingWaitingMessage, showLoadingWaitingMessageRef, questionOnDemand, questionOnDemandRef, setShowLoadingWaitingMessage, setHasRendered })

  const { isButton } = messageThread

  let leftChatClass = isDarkMode ? "left-chat-box dark chat-message" : "left-chat-box chat-message"
  let leftOverlapCoverClass = isDarkMode ? "left-box-overlap-cover dark" : "left-box-overlap-cover"
  let rightChatClass = isDarkMode ? "right-chat-box dark chat-message" : "right-chat-box chat-message"
  let rightOverlapCoverClass = isDarkMode ? "right-box-overlap-cover dark" : "right-box-overlap-cover"

  const isAfterHours = !!messageThread.closePrice
  const is24hrMarket = (messageThread.assetType == "cryptos" || messageThread.assetType == "forex")

  return (
    <Box className="chat_message_container" sx={{width: '90%'}}>
      {(messageThread.sender == "human") &&
        <Box className="outgoing_container">
        { !isButton &&
          <Box sx={{ margin: 'auto', color: '#FFFFFF' }}>
            <Box className="bubble_container">
              <div className={rightChatClass} style={{fontWeight: 500}}>
                { messageThread.message }
                <Box className={rightOverlapCoverClass}/>
              </div>
            </Box>
          </Box>
        }
        </Box>
      }
      {(messageThread.sender == "bot" ) && 
        <Box className="bubble_container" 
          sx={{width: '100%', flexDirection: 'column', justifyContent: 'flex-end'}}>
          <Box className={leftChatClass}>
          { RenderResponseString({ messageThread }) }

          { hasRendered &&
            <Box sx={{ marginTop: isMobile ? '0px' : '8px', marginLeft: isMobile ? '0px' :'20px', marginRight: isMobile ? '0px' :'20px', marginBottom: isMobile ? '0px' :'20px', marginTop: isMobile ? '0px' : '6px' }}>
              <Box id={`chart-container-${idx}`} sx={{
                  minHeight: '300px', width: '100%', marginTop: '10px'}}>
                <canvas ref={chartContainerRef} id={`myChart-${idx}`}></canvas>
              </Box>
            </Box>
          }
            <Box className="answer-description-text" sx={{
              marginTop: '7px',
              color: isDarkMode ? '#848484' : '#888888',//'#B7B7B7',
              display: 'flex', alignItems: 'stretch', justifyContent: 'space-between',
              }}>
              <Box sx={{margin: 'auto auto auto 0px'}}>
                {messageThread.tickerDescription && (messageThread.tickerDescription.length < 2
                ?
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                { !isDarkMode && messageThread.logoUrls.length > 0 &&
                  <div style={{height: '32px', marginRight: '8px'}}>
                    { messageThread.logoUrls.map((logoUrl, idx) => {
                      return (
                        <img src={`${logoUrl}`} style={{maxHeight: '100%', mixBlendMode: "multiply"}}/>
                      )
                    })}
                  </div>
                }
                <Box>
                { messageThread.tickerDescription.map((td, idx) => {
                  return (
                  (idx == messageThread.tickerDescription.length-1) ? 
                    messageThread.currentPrice ? td + `  (${messageThread.ticker})` : td
                  :
                    td+" | "
                  )
                })}
                { messageThread.currentPriceUpdatedAt &&
                  <>
                    { messageThread.currentPrice &&
                      <>
                        { (isAfterHours && !is24hrMarket) ?
                          <>
                          <Box sx={{marginTop: '4px'}}>
                            {`Close Price: `}{`$${messageThread.closePrice}`}
                            { messageThread.pricePctChange1d != null &&
                              <span style={{color: parseFloat(messageThread.pricePctChange1d) >= 0 ? '#188038' : '#E34234', opacity: parseFloat(messageThread.pricePctChange1d) >= 0 ? 1.0 : 0.75}}>
                                {` (${parseFloat(messageThread.pricePctChange1d) >= 0 ? '+':''}${Math.round(10000*parseFloat(messageThread.pricePctChange1d))/100}%)`}
                              </span>
                            }
                          </Box>
                          { (messageThread.pricePctChangeAh != null) &&
                          <>
                            {`After hours: `}{`$${messageThread.currentPrice}`} <span style={{color: parseFloat(messageThread.pricePctChangeAh) >= 0 ? '#188038' : '#E34234', opacity: parseFloat(messageThread.pricePctChangeAh) >= 0 ? 1.0 : 0.75}}>
                              {` (${parseFloat(messageThread.pricePctChangeAh) >= 0 ? '+':''}${Math.round(10000*parseFloat(messageThread.pricePctChangeAh))/100}%)`}
                            </span>
                          </>
                          }
                          </>
                        :
                          <Box sx={{marginTop: '4px'}}>
                            {`Price: `}<b>{`$${messageThread.currentPrice}`} </b> 
                            { (messageThread.pricePctChange1d != null) &&
                              <>
                                { is24hrMarket ?
                                  <span style={{color: parseFloat(messageThread.pricePctChange24hr) >= 0 ? '#188038' : '#E34234', opacity: parseFloat(messageThread.pricePctChange1d) >= 0 ? 1.0 : 0.75}}>
                                  {` (${parseFloat(messageThread.pricePctChange24hr) >= 0 ? '+':''}${Math.round(10000*parseFloat(messageThread.pricePctChange24hr))/100}% last 24hrs)`}
                                  </span>
                                :
                                  <span style={{color: parseFloat(messageThread.pricePctChange1d) >= 0 ? '#188038' : '#E34234', opacity: parseFloat(messageThread.pricePctChange1d) >= 0 ? 1.0 : 0.75}}>
                                    {` (${parseFloat(messageThread.pricePctChange1d) >= 0 ? '+':''}${Math.round(10000*parseFloat(messageThread.pricePctChange1d))/100}%)`}
                                  </span>
                                }
                              </>
                            }
                          </Box>
                        }
                        { isLastMessage && 
                          <Box sx={{marginTop: '4px'}}>
                            Last updated: {messageThread.currentPriceUpdatedAt}
                          </Box>
                        }
                      </>
                    }
                  </>
                }
                </Box>
                </Box>
                :
                <Box>
                  {`${messageThread.tickerDescription.length} investments analyzed`}
                  { messageThread.currentPriceUpdatedAt &&
                    <Box sx={{marginTop: '4px'}}>
                      Last updated: {messageThread.currentPriceUpdatedAt}
                    </Box>
                  }
                </Box>
              
                )}
              </Box>
              { (isLastMessage && messageThread.tickerDescription && messageThread.tickerDescription.length > 0) &&
                <Box sx={{ marginTop: '10px' }}>
                  <Box sx={{display: 'flex', gap: '20px'}}>
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '5px', color: isDarkMode ? '#fff':'#000', fontSize: '13px', '&:hover': {cursor: 'pointer'}}}
                      onClick={() => {
                        handleSharing(setModalVisible, setShowChatShare, true)
                      }}
                    >
                      <img src={isDarkMode ? ShareButtonDark : ShareButton} style={{maxHeight: '16px'}} /> Share
                    </Box>
                    { showAddWatchlist && <>
                      { messageThread.answeredTickerIds.length > 0 &&
                        <Box sx={{position: 'relative', display: 'flex', alignItems: 'center', gap: '5px', color: isDarkMode ? '#fff':'#000', fontSize: '13px', '&:hover': {cursor: 'pointer'}}}
                          onClick={() => {
                            if (isLoggedIn) {
                              if (userData && (userData.has_active_subscription || userData.is_admin_user)) {  
                                const intIds = messageThread.answeredTickerIds.map(v => parseInt(v))
                                if (countOfWatchlistTicker + intIds.length > get_max_watchlist_items(userData)) {
                                  setWatchlistCountError(true)
                                  setTimeout(() => {
                                    setWatchlistCountError(false)
                                  }, 4000)
                                } else {
                                  setWatchlistCountError(false)
                                  updateUserWatchlist(intIds, null, appProps.setWatchlistData)
                                  setShowAddingWatchlist(true)
                                  setShowWatchlistBlueDot(true)
                                  setShowWatchlistFlicker(true)
                                  setTimeout(() => {
                                    setShowAddingWatchlist(false)
                                    setShowAddedatchlist(true)
                                  }, 1300)
                                  setTimeout(() => {
                                    setShowAddWatchlist(false)
                                  }, 2600)
                                  setTimeout(() => {
                                    setShowWatchlistFlicker(false)
                                    setShowAddedatchlist(false)
                                  }, 5000)
                                } 
                              } else {
                                // Show paywall with custom message
                                appProps.setPaywallMessage(watchlistPaywallModalMessage)
                                appProps.setShowPaywallModal(true)
                              }

                            } else {
                              appProps.setShowSignUpModal(true)
                            }
                          }}
                        >
                          { showAddingWatchlist ? 
                            <> <img src={WatchlistUpdate} style={{maxHeight: '16px'}} className={"rotate-animation"} /> Watch </>
                            :
                            <> <img src={showAddedatchlist ? PaywallSelectCheck : (isDarkMode ? WatchButtonDark : WatchButton)} style={{maxHeight: '16px'}} /> Watch </>
                          }
                          { watchlistCountError &&
                            <Box sx={{position: 'absolute', border: '#F6F6F6', backgroundColor: isDarkMode ? '#131521' : '#F6F6F6', left: '-86px', width: '272px', top: isMobile ? '-50px':'25px', borderRadius: '8px', display: 'flex', flexDirection: 'column', gap: '6px', padding: '0px', }}>
                              <Box sx={{ border: '#F6F6F6', backgroundColor: isDarkMode ? '#131521' : '#F6F6F6', borderRadius: '8px', padding: '7px' }}>
                                <span className={isDarkMode ? "base-text dark" : "base-text"} style={{fontSize: '12px'}}>
                                {`You can only have ${get_max_watchlist_items(userData)} items at a time in your watchlist. Please remove to add more.`}
                                </span>
                              </Box>
                            </Box>
                          }
                        </Box>
                      }
                      </>
                    }
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '5px', color: isDarkMode ? '#fff':'#000', fontSize: '13px', '&:hover': {cursor: 'pointer'}}}
                      onClick={() => {
                        if (!preventQuestionInput) {
                          setPreventQuestionInput(true)
                          setTimeout(() => {
                            if (!secondaryExperienceType || secondaryExperienceType == "new-user") {
                              onQuestionSubmit("Update")
                            } else if (secondaryExperienceType == "ask-share") {
                              if (questionNumber == 1) {
                                handleSharing(setModalVisible, setShowChatShare, false)
                                setTimeout(() => {
                                  setUserData({...userData, showAskShareMessage: false})
                                }, 1500)
                                setPreventQuestionInput(false)
                                userShareExperienceButton("share")
                              } else {
                                setUserData({...userData, showAskShareMessage: false})
                                setPreventQuestionInput(false)
                                userShareExperienceButton("continue")
                              }
                            }
                          }, 50)
                        }
                      }}
                    >
                      <img src={isDarkMode ? RefreshDataDark : RefreshData} style={{marginTop: '1px', maxHeight: '16px'}} /> Update Data
                    </Box>
                  </Box>
                </Box>
              }
            </Box>
            <Box className={leftOverlapCoverClass}/>
          </Box>
        </Box>
      }
      { loadingResponse && isLastMessage &&
      <Box>
        {showLoadingWaitingMessage == 1 && 
          <ResponseWaitingSecond text={"Just a moment"} sx={{width: '130px'}}/>
        }
        {showLoadingWaitingMessage == 2 && 
          <ResponseWaitingSecond text={"Almost there"} sx={{width: '120px'}}/>
        }
        {showLoadingWaitingMessage == 3 && 
          <ResponseWaitingSecond text={"Just a little longer"} sx={{width: '150px'}}/>
        }
        {showLoadingWaitingMessage == 4 && 
          <ResponseWaitingSecond text={"Still working"} sx={{width: '112px'}}/>
        }
        {showLoadingWaitingMessage == 0 && 
         <ResponseWaiting/>
        }
      </Box>
      }

      { loadingResponse && isLastMessage &&
        <>
        {showLoadingWaitingMessage == 'demand-1' && <OnDemandWaiting text={"Updating Real-Time"} sx={{width: '152'}} hideDotDotDot={true}/>}
        </>
      }
    </Box>
  )
}

export default ChatMessage
