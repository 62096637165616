import { Box, Button } from '@mui/material';
import { Navbar } from "../components/Navbar";
import { themeColors } from "../utils/constants";
import { useNavigate } from "react-router-dom"

const ErrorPage = (props) => {
  const { appProps } = props
  const navigate = useNavigate()

  return (
    <Box sx={{width: '100%', height:'100vh', overflowY: 'auto', backgroundColor: themeColors.backgroundColor}}>
      <Box sx={{marginTop: '100px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Box className="account-page-text" sx={{color: themeColors.color, lineHeight: '166%', margin: '0px 40px', width: '100%', maxWidth: '700px', textAlign: 'center'}}>
          <b>The AI didn't break, but this page doesn't exist!</b><br/>
          <Button variant="contained" className="i_understand_button" sx={{
            marginTop: '16px',
            textTransform: 'none',
            borderRadius: '24px',
            background: themeColors.buttonColor,// : '#696969',
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
            width: '180px',
            height: '38px',
            maxWidth: '100%',
            textAlign: 'center',
            '&.Mui-disabled': {
              backgroundColor: '#696969',
            },
          }}
          onClick={() => {
            navigate('/chat')
          }}
          >
            <Box className="base-text i_understand_text" sx={{
              display: 'flex',
              fontSize: '14px',
              fontWeight: 500, alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: '1px',
              color: '#F2F2F2',
            }}>
              {'Return to Chat'}
            </Box>
          </Button>
      </Box>
      </Box>
      <Box sx={{position: 'fixed', left: '0px', top: '0px', width: '100%'}}>
      <Navbar appProps={appProps} headerDescription={'404'}/>
      </Box>
    </Box>
  )
}
export default ErrorPage
